<template>
  <Toast />
  <template class="flex flex-col gap-4">
    <section class="box p-4">
      <div>
        <Steps :model="items" :readonly="false" class="text-sm"/>
      </div>
    </section>

    <section class="box p-4">
      <router-view v-slot="{Component}">
        <keep-alive>
          <component :is="Component"/>
        </keep-alive>
      </router-view>
    </section>
  </template>
</template>

<script>

import { ref } from 'vue'

export default {
  name: 'crearJuzgado',
  setup () {
    const items = ref([
      {
        label: 'Información Accionado',
        to: '/juridica/procesosjudiciales/crear/buscar-accionado'
      },
      {
        label: 'Información Accionante',
        to: '/juridica/procesosjudiciales/crear/buscar-accionante'
      },
      {
        label: 'Información Juzgado',
        to: '/juridica/procesosjudiciales/crear/informacion-juzgado'
      },
      {
        label: 'Detalle del proceso',
        to: '/juridica/procesosjudiciales/crear/detalle-proceso'
      }
    ])

    return {
      items
    }
  }
}
</script>

<style scoped>

</style>
